/* Contact */

#contact {
  background: #141c3a;
  height: 100vh;
  width: 100%;
  border: 1rem solid #141c3a;
}

.contact-heading {
  color: white;
}

.contact-card {
  color: white;
  display: grid;
  grid-gap: 0.5rem;
  text-align: center;
}

.anchor {
  color: white;
}

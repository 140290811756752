article {
  text-align: center;
  font-size: 1.5vh;
  display: grid;
  place-items: center;
  grid-gap: 1rem;
  padding: 2rem 2rem;
  margin: 1rem 0rem;
  color: white;
  border-radius: 1rem;
  background: #141c3a;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
}

img {
  width: 4rem;
  box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.2);
  border-radius: 0.25rem;
}

a {
  color: #010409;
  text-decoration: none;
}

.button-container {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}

.secondary-variant {
  color: rgb(185, 125, 255);
  border: 3px solid rgb(185, 125, 255);
}

@media only screen and (min-width: 1024px) {
  article {
    font-size: 2.5vh;
  }
}

@media only screen and (min-width: 1366px) {
  .button-container {
    margin-top: 3rem;
  }

  .source-button {
    height: 4rem;
    width: 12rem;
  }

  .demo-button {
    height: 4rem;
    width: 12rem;
  }
}

/* Global */
@import url("https://fonts.googleapis.com/css2?family=Roboto&family=Satisfy&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  text-align: justify;
  font-size: clamp(1rem, 2.5vw, 2rem);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
* {
  box-sizing: border-box;
  margin: 0;
}
section {
  display: grid;
  grid-gap: 1.5rem;
  place-items: center;
  padding: 2rem;
  min-height: max-content;
}
.intro-container {
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;
  font-weight: bolder;
}

.head-picture {
  width: 5rem;
  margin: 0;
}

.buttons-container {
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  margin: 5vh auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
  font-size: clamp(1rem, 1vw, 1rem);
}

.btn {
  display: grid;
  place-items: center;
  padding: 0.5rem 1rem;
  text-decoration: none;
  border-radius: 0.5rem;
  width: 100%;
  box-shadow: 0rem 0rem 0.6rem rgba(0, 0, 0, 0.2rem);
}

.primary {
  color: white;
  background: rgb(130, 50, 210);
  background: linear-gradient(
    90deg,
    rgba(160, 50, 210, 1) 0%,
    rgba(120, 50, 210, 1) 100%
  );
}

.secondary {
  color: rgb(130, 50, 210);
  border: 3px solid rgb(130, 50, 210);
}

a:hover {
  filter: brightness(1.1);
  transform: scale(1.02);
  box-shadow: 0rem 0rem 0.3rem rgba(0, 0, 0, 0.2);
}

.secondary:hover {
  background: rgba(130, 50, 210, 0.1);
}

@media only screen and (min-width: 1024px) {
  .App {
    display: grid;
    place-items: center;
  }

  body {
    font-size: 3vh;
  }

  span {
    font-size: 145%;
  }

  .primary {
    width: 10rem;
    height: 4rem;
  }

  .secondary {
    width: 10rem;
  }

  .head-picture {
    width: 10rem;
  }
}

@media only screen and (min-width: 1450px) {
  .head-picture {
    width: 15rem;
  }

  .buttons-container {
    margin: 15vh auto;
  }
}

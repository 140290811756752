/* Introduction */

#intro {
  height: 100%;
  width: 100%;
}

.intro-container {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.logo-container {
  width: 100%;
  display: grid;
  place-items: center;
  grid-gap: 1rem;
  margin-top: 2rem;
}

.head-picture {
  width: 25vw;
}

.name {
  text-align: left;
  line-height: 1.1;
  font-size: 20vw;
  font-family: "Satisfy", cursive;
  background: rgb(130, 50, 210);
  background: -webkit-linear-gradient(rgb(160, 50, 210), rgb(120, 50, 210));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.last {
  width: 100%;
  margin-left: 0.5rem;
}

.type-writer {
  font-size: clamp(1rem, 1.5vw, 2rem);
  width: 75vw;
  margin: auto;
  text-align: justify;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media only screen and (min-width: 1024px) {
  .intro-container {
    margin: auto;
    width: 50%;
    grid-template-columns: 1fr;
    grid-gap: 0;
  }

  .logo-container {
    grid-template-columns: 1fr 1fr;
    grid-gap: 0;
  }

  .head-picture {
    width: 15vw;
    margin: 0;
  }

  .name {
    margin-top: 1rem;
    font-size: 6vw;
  }

  .type-writer {
    margin: auto;
    height: 18rem;
  }
}

/* Portfolio  */

h1 {
  color: black;
}

#portfolio {
  display: grid;
  grid-gap: 2rem;
  height: 100%;
  width: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(20, 28, 58, 1) 100%
  );
}

.paragraph {
  margin: auto;
  width: 80%;
}

.projects-container {
  display: grid;
  grid-template-columns: 1fr;
}

@media only screen and (min-width: 1024px) {
  .projects-container {
    grid-template-columns: 1fr 1fr;
    grid-gap: 2.5rem;
  }
}
